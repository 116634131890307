import React from "react"
import BackgroundImage from "gatsby-background-image"

const OurFuture = (props) => {
  const data = props.data

  const sources = [
    data.ourFutureBackgroundImageMobile.fluid,
    {
      ...data.ourFutureBackgroundImageDesktop.fluid,
      media: `(min-width: 640px)`,
    },
  ]
  return (
    <BackgroundImage fluid={sources}>
      <div className="container mx-auto t-grid py-20 lg:py-28 text-white space-y-6 lg:items-center">
        <div className="col-span-full lg:col-start-2 lg:col-span-5">
          <h2>{data.ourFutureSectionHeading}</h2>
        </div>
        <div className="col-span-full lg:col-start-7 lg:col-span-5">
          <div className="col-span-full lg:col-start-7 lg:col-span-5 space-y-10">
            <div>
              <p className="light-2 body-2">
                {data.ourFutureSectionParagraph.ourFutureSectionParagraph}
              </p>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default OurFuture
