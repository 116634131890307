import React from "react"
import LeftTitle from "./lefttitle"

const AboutUs = (props) => {
  const data = props.data
  return (
    <div className="bg-white">
      <div className=" px-7 sm:px-14 lg:px-0 sm:container bg-about-us-mobile lg:bg-about-us bg-cover bg-right-bottom welcome mx-auto t-grid text-black pt-16 pb-12 lg:pb-20 space-y-6 lg:space-y-0 relative -mb-8 -top-12 lg:-top-16">
        <div className="col-span-full lg:col-start-2 lg:col-span-7 space-y-8">
          <h2>{data.aboutUsSectionHeading}</h2>
          {data.aboutUsSectionParagraphs.map(element => {
            return(
              <p className="body-2">
                {element.paragraph.paragraph}
              </p>)
          })}
          
        </div>
        <br />
        <div className="col-span-full lg:col-start-10 lg:col-span-3 space-y-12">
          <LeftTitle text={data.byTheNumbersHeading} />
          <div className="flex lg:flex-col justify-between lg:space-y-12">
            <div>
              <span className="eyebrow">{data.byTheNumbersEyebrow1}</span>
              <h3>{data.byTheNumbersText1}</h3>
            </div>
            <div>
              <span className="eyebrow">{data.byTheNumbersEyebrow2}</span>
              <h3>{data.byTheNumbersText2}</h3>
            </div>
            <div>
              <span className="eyebrow">{data.byTheNumbersEyebrow3}</span>
              <h3>{data.byTheNumbersText3}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
