import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutHero from "../components/abouthero"
import AboutUs from "../components/aboutus.js"
import Founder from "../components/founder"
import OurFuture from "../components/ourfuture"

const ContactPage = ({data}) => {
  const aboutPageData = data.contentfulAboutUsPage
  return (
    <Layout>
      <SEO title="Our Company" />
      <AboutHero data={aboutPageData} />
      <AboutUs data={aboutPageData} />
      <Founder data={aboutPageData} />
      <OurFuture data={aboutPageData} />
    </Layout>
    )
  }

export const query = graphql`
  query aboutPageData {
    contentfulAboutUsPage {
      heroHeadingText
      heroBackgroundImageDesktop {
        fluid(maxWidth: 1920, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroBackgroundImageMobile {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      aboutUsSectionHeading
      aboutUsSectionParagraphs {
          paragraph {
            paragraph
          }
        }
      byTheNumbersHeading
      byTheNumbersEyebrow1
      byTheNumbersText1
      byTheNumbersEyebrow2
      byTheNumbersText2
      byTheNumbersEyebrow3
      byTheNumbersText3
      memberProfiles {
        heading
        profilePicture {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        name
        position
        bio {
          bio
        }
      }
      ourFutureSectionHeading
      ourFutureSectionParagraph {
        ourFutureSectionParagraph
      }
      ourFutureBackgroundImageDesktop {
        fluid(maxWidth: 1920, quality: 85) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ourFutureBackgroundImageMobile {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default ContactPage
